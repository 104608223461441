import React from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";

const editorStyle: React.CSSProperties = {
  fontFamily: '"Fira code", "Fira Mono", monospace',
  fontSize: 12,
  backgroundColor: "#212F3C",
};

interface SqlCodeEditorProps {
  code: string;
  readOnly?: boolean;
  onValueChange?: (code: string) => void;
  height?: string;
  widht?: string;
}

const noOp: (code: string) => void = () => {};

export const SqlCodeEditor: React.FC<SqlCodeEditorProps> = (props) => (
  <Editor
    value={props.code}
    onValueChange={props.onValueChange ?? noOp}
    highlight={(code) => highlight(code, languages.sql, "sql")}
    padding={10}
    readOnly={props.readOnly}
    style={{
      ...editorStyle,
      height: props.height ?? "350px",
      width: props.widht,
      marginBottom: "10px",
    }}
  />
);
