import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import "prismjs/components/prism-sql";
import "prism-themes/themes/prism-hopscotch.css";
import { useService } from "../services/ai-labeling.service";
import { PageLoader } from "src/components/page-loader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { SqlCodeEditor } from "src/components/sql-code-editor";
import { sqlFormatter } from "src/util/sql-formatter";

const defaultSqlCode = sqlFormatter(
  "select * from data_mart_current.view_ennabl_policy"
);

export const FineTunePage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sampleList, setSampleList] = useState<Array<string>>([]);
  const [selectedSample, setSelectedSample] = useState<string>("");
  const [code, setCode] = useState<string>(defaultSqlCode);
  const [aiCode, setAiCode] = useState<string>("");
  const [snackbarMessage, setSnackbarMessage] = useState<string>(``);

  const aiService = useService();

  const doSubmitCode = () => {
    aiService
      .sendSample({ prompt: selectedSample, sql: code, source: "user" })
      .then((r) => {
        setSnackbarMessage("Saved");
        setSampleList(sampleList.filter((s) => s != selectedSample));
        setSelectedSample("");
        setCode(defaultSqlCode);
        setAiCode("");
      });
  };

  const doSelectSample = (prompt: string) => {
    setSelectedSample(prompt);
    setAiCode("");
    aiService
      .sendPrompt(prompt)
      .then((r) => setAiCode(sqlFormatter(r.data.sql)));
  };

  const doReformatCode = () => {
    setCode(sqlFormatter(code));
  };

  useEffect(() => {
    aiService.listPendingSamples().then((samples) => {
      setSampleList((samples.data as any[]).map((s) => s.prompt));
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <PageLayout>
        <PageLoader />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div>
        <h3>Select a prompt below and provide SQL:</h3>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <List>
              {sampleList.map((s) => (
                <ListItemButton
                  selected={selectedSample === s}
                  onClick={() => doSelectSample(s)}
                >
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "16px" }}
                    primary={s}
                  />
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid item xs={9}>
            {selectedSample ? (
              <div style={{ marginLeft: "20px" }}>
                <Alert severity="info" style={{ fontSize: 14 }}>
                  {selectedSample}
                </Alert>

                <h3>Model Response:</h3>

                {aiCode ? (
                  <SqlCodeEditor code={aiCode} readOnly={true} />
                ) : (
                  <PageLoader />
                )}

                <h3>SQL:</h3>
                <SqlCodeEditor code={code} onValueChange={(c) => setCode(c)} />
                <Button
                  variant="contained"
                  size="large"
                  onClick={doSubmitCode}
                  style={{ marginRight: "10px" }}
                >
                  Submit
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  onClick={doReformatCode}
                >
                  Format SQL
                </Button>
              </div>
            ) : (
              <div />
            )}
          </Grid>
        </Grid>

        <Snackbar
          message={snackbarMessage}
          autoHideDuration={6000}
          style={{ fontSize: 100 }}
          open={snackbarMessage != ""}
          onClose={(e) => setSnackbarMessage("")}
        />
      </div>
    </PageLayout>
  );
};
