import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse } from "../models/api-response";
import { AppError } from "../models/app-error";

const baseConfig = {
  headers: {
    "content-type": "application/json",
  },
};

export const callExternalApi = async (
  accessToken: string,
  config: Partial<AxiosRequestConfig>
): Promise<ApiResponse> => {
  const requestConfig = {
    ...config,
    headers: {
      ...config.headers,
      ...baseConfig.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };

  try {
    const response: AxiosResponse = await axios(requestConfig);
    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.code === 'ERR_NETWORK') {
        window.location.href = '/403';
      }

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && (response.data as AppError).message) {
        message = (response.data as AppError).message;
      }

      return {
        data: null,
        error: {
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        message: (error as Error).message,
      },
    };
  }
};
