import { format as sqlFormat } from "sql-formatter";

export const sqlFormatter = (code: string) => {
  try {
    return sqlFormat(code, {
      tabWidth: 2,
      keywordCase: "upper",
    });
  } catch (ex) {
    console.log(`unable to format ${code}`);
    return code;
  }
};
