import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-sql";
import "prism-themes/themes/prism-hopscotch.css";
import { useService } from "../services/ai-labeling.service";
import { PageLoader } from "src/components/page-loader";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { SqlCodeEditor } from "src/components/sql-code-editor";
import { sqlFormatter } from "src/util/sql-formatter";
import { useSearchParams } from "react-router-dom";

const editorStyle: React.CSSProperties = {
  fontFamily: '"Fira code", "Fira Mono", monospace',
  fontSize: 12,
  backgroundColor: "#212F3C",
};

export const PromptPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [prompt, setPrompt] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [code, setCode] = useState<string>(``);
  const [apiPrompt, setApiPrompt] = useState<string>(``);
  const [apiCode, setApiCode] = useState<string>(``);
  const [sampleCode, setSampleCode] = useState<string>(``);
  const [snackbarMessage, setSnackbarMessage] = useState<string>(``);

  const aiService = useService();

  const doSendPrompt = (newPrompt: string) => {
    setPrompt(newPrompt);
    setIsLoading(true);
    aiService.sendPrompt(newPrompt).then((r) => {
      const resultCode = sqlFormatter(r.data.sql);
      const resultSampleCode = sqlFormatter(r.data.sample_sql ?? "");
      setCode(resultCode);
      setApiCode(resultCode);
      setApiPrompt(newPrompt);
      setSampleCode(resultSampleCode);
      setIsLoading(false);
      setShowFeedback(true);
    });
  };

  useEffect(() => {
    const query = searchParams.get("query");
    if (query && query.trim().length > 0) {
      console.log("SENDING PROMPT " + searchParams.get("query"));
      setSearchParams({});
      doSendPrompt(searchParams.get("query")!);
    }
  });

  const doUpdateSample = () => {
    aiService.sendSample({ prompt, sql: code, source: "user" }).then((r) => {
      setSnackbarMessage("Saved");
    });
  };

  const doClearSample = () => {
    aiService.sendSample({ prompt, sql: "", source: "user" }).then((r) => {
      setCode("");
      setSampleCode("");
      setSnackbarMessage("Saved");
    });
  };

  const updatePrompt = (newPrompt: string) => {
    setPrompt(newPrompt);
    setShowFeedback(apiPrompt === newPrompt && apiPrompt !== "");
  };

  return (
    <PageLayout>
      <div>
        <h3>Prompt:</h3>
        <Editor
          value={prompt}
          onValueChange={updatePrompt}
          highlight={(code) => highlight(code, languages.text, "text")}
          padding={10}
          style={editorStyle}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              doSendPrompt(prompt);
            }
          }}
        />

        {!isLoading && sampleCode ? (
          <div>
            <h3>Existing Sample SQL:</h3>
            <SqlCodeEditor code={sampleCode} readOnly={true} height="auto" />
          </div>
        ) : (
          <div />
        )}

        {isLoading ? (
          <PageLoader />
        ) : (
          <div>
            <h3>SQL:</h3>
            <SqlCodeEditor code={code} onValueChange={(c) => setCode(c)} />
          </div>
        )}

        {showFeedback ? (
          <div style={{ paddingTop: 20 }}>
            <Button
              variant="contained"
              color="success"
              size="large"
              onClick={doUpdateSample}
              style={{ marginRight: 20 }}
              disabled={code === sampleCode}
            >
              {sampleCode ? "Update Sample" : "Create Sample"}
            </Button>

            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={doClearSample}
              style={{ marginRight: 20 }}
            >
              Clear Sample
            </Button>
          </div>
        ) : (
          <div />
        )}

        <Snackbar
          message={snackbarMessage}
          autoHideDuration={6000}
          style={{ fontSize: 100 }}
          open={snackbarMessage != ""}
          onClose={(e) => setSnackbarMessage("")}
        />
      </div>
    </PageLayout>
  );
};
