import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import "prismjs/components/prism-sql";
import "prism-themes/themes/prism-hopscotch.css";
import { useService } from "../services/ai-labeling.service";
import { PageLoader } from "src/components/page-loader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const MIN_PROMPT_LEN = 3;

interface SearchResult {
  page: string;
  section: string;
  url: string;
}

export const SearchPage: React.FC = () => {
  const [query, setQuery] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<SearchResult[]>([]);

  const aiService = useService();

  const doSubmit = () => {
    setIsLoading(true);
    aiService.decisionsSearch(query).then((r) => {
      setResult(r.data);
      setIsLoading(false);
    });
  };

  return (
    <PageLayout>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <h3>Query</h3>
          </Grid>
          <Grid item xs={11}>
            <TextField
              id="outlined-basic"
              variant="filled"
              fullWidth={true}
              onChange={(e) => setQuery(e.target.value)}
              InputProps={{ style: { fontSize: 14 } }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && query.length >= MIN_PROMPT_LEN) {
                  e.preventDefault();
                  doSubmit();
                }
              }}
            />
          </Grid>
        </Grid>

        {isLoading ? (
          <PageLoader />
        ) : (
          <div>
            <Button
              variant="contained"
              color="success"
              size="large"
              onClick={doSubmit}
              style={{ marginTop: 20 }}
              disabled={query.length < MIN_PROMPT_LEN}
            >
              Search
            </Button>

            {result.length > 0 ? (
              result.map((r) => (
                <h3>
                  {r.page} #{r.section} <a href={r.url} className="externalLink">link</a>
                </h3>
              ))
            ) : (
              <h3>No Results</h3>
            )}
          </div>
        )}
      </div>
    </PageLayout>
  );
};
