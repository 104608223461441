import React from "react";
import { AppMenu } from "./app-menu";

interface Props {
  children: JSX.Element;
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="page-layout">
      <AppMenu />
      <div className="page-layout__content">{children}</div>
    </div>
  );
};
