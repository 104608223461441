import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-sql";
import "prism-themes/themes/prism-hopscotch.css";
import { useService } from "../services/ai-labeling.service";
import { PageLoader } from "src/components/page-loader";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { SqlCodeEditor } from "src/components/sql-code-editor";
import { sqlFormatter } from "src/util/sql-formatter";
import { useSearchParams } from "react-router-dom";
import Divider from "@mui/material/Divider";

const editorStyle: React.CSSProperties = {
  fontFamily: '"Fira code", "Fira Mono", monospace',
  fontSize: 12,
  backgroundColor: "#212F3C",
};

export const RunPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [prompt, setPrompt] = useState<string>("");
  const [apiPrompt, setApiPrompt] = useState<string>(``);

  const [responseSql, setResponseSql] = useState<string>(``);
  const [responseData, setResponseData] = useState<string>(``);
  const [responseMetadata, setResponseMetadata] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);

  const [snackbarMessage, setSnackbarMessage] = useState<string>(``);

  const aiService = useService();

  const doSendPrompt = (newPrompt: string) => {
    setPrompt(newPrompt);
    setIsLoading(true);
    aiService.executePrompt(newPrompt).then((r) => {    
      setResponseSql(sqlFormatter(r.data.sql));
      setResponseMetadata(r.data.resolve_metadata);
      setResponseData(r.data.result?.data ?? r.data.result?.url ?? "");

      setApiPrompt(newPrompt);
      setIsLoading(false);
      setShowFeedback(true);
    });
  };

  useEffect(() => {
    const query = searchParams.get("query");
    if (query && query.trim().length > 0) {
      setSearchParams({});
      doSendPrompt(searchParams.get("query")!);
    }
  });

  const updatePrompt = (newPrompt: string) => {
    setPrompt(newPrompt);
    setShowFeedback(apiPrompt === newPrompt && apiPrompt !== "");
  };

  const doSendFeedback = (prompt: string, sql: string, isCorrect: boolean) => {
    aiService.sendFeedback(prompt, sql, isCorrect).then((r) => {
      setSnackbarMessage("Feedback is saved. Thank you.");
      setShowFeedback(false);
    });
  };

  const renderMetadataItem = (metadataItem: any) => {
    const chipStyle: React.CSSProperties = {
      marginRight: 10,
      marginBottom: 10,
      fontSize: 15,
    };
    return (
      <div>
        <Chip label={metadataItem.entity} color="primary" style={chipStyle} />
        <Chip label={metadataItem.value} color="success" style={chipStyle} />
      </div>
    );
  };

  const renderMetadata = () => {
    return <div>{responseMetadata.map(renderMetadataItem)}</div>;
  };

  const renderResultPart = () => {
    if (!responseSql) {
      return <div></div>;
    }
    console.log("'" + responseData + "'");
    return (
      <div>
        <Alert severity="info" style={{ fontSize: 14 }}>
          {apiPrompt}
        </Alert>

        <Editor
          value={responseData || "No result"}
          readOnly={true}
          onValueChange={updatePrompt}
          highlight={(code) => highlight(code, languages.js, "js")}
          padding={10}
          style={editorStyle}
        />

        <h3>Metadata</h3>
        {renderMetadata()}

        <h3>SQL:</h3>
        <SqlCodeEditor code={responseSql} readOnly={true} />
      </div>
    );
  };

  return (
    <PageLayout>
      <div>
        <h3>Enter your query and press enter:</h3>
        <Editor
          value={prompt}
          onValueChange={updatePrompt}
          highlight={(code) => highlight(code, languages.text, "text")}
          padding={10}
          style={editorStyle}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              doSendPrompt(prompt);
            }
          }}
        />

        <Divider
          style={{ marginTop: 40, marginBottom: 20 }}
          textAlign="center"
          variant="fullWidth"
        >
          <Chip label="Result" style={{ fontSize: 14 }} />
        </Divider>

        {isLoading ? <PageLoader /> : renderResultPart()}

        {showFeedback ? (
          <div style={{ paddingTop: 20 }}>
            <Button
              variant="contained"
              color="success"
              size="large"
              style={{ marginRight: 20 }}
              onClick={(e) => doSendFeedback(apiPrompt, responseSql, true)}
            >
              Looks Good
            </Button>

            <Button
              variant="contained"
              color="error"
              size="large"
              style={{ marginRight: 20 }}
              onClick={(e) => doSendFeedback(apiPrompt, responseSql, false)}
            >
              Wrong
            </Button>
          </div>
        ) : (
          <div />
        )}

        <Snackbar
          message={snackbarMessage}
          autoHideDuration={6000}
          style={{ fontSize: 100 }}
          open={snackbarMessage != ""}
          onClose={(e) => setSnackbarMessage("")}
        />
      </div>
    </PageLayout>
  );
};
