import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import "prismjs/components/prism-sql";
import "prism-themes/themes/prism-hopscotch.css";
import { useService } from "../services/ai-labeling.service";
import { PageLoader } from "src/components/page-loader";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { SqlCodeEditor } from "src/components/sql-code-editor";
import { sqlFormatter } from "src/util/sql-formatter";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const SqlCodeCell = (props: { row: any }) => {
  const { row } = props;
  const navigate = useNavigate();

  const doubleClick = () => {
    navigate(`/prompt?query=${encodeURIComponent(row.prompt)}`);
  };
  return (
    <div
      style={{ width: "100%" }}
      onClick={(e) => {
        if (e.detail === 2) doubleClick();
      }}
    >
      <SqlCodeEditor
        readOnly={true}
        code={sqlFormatter(row.sql)}
        height="auto"
        widht="100%"
      />
    </div>
  );
};

const columns: GridColDef[] = [
  {
    field: "createdAt",
    headerName: "Date",
    width: 100,
    sortable: true,
    renderCell: (params) => {
      const timestamp = parseInt(params.value);
      const date = new Date(timestamp);
      return (
        <div>
          <div>{date.toLocaleTimeString("en-US")}</div>
          <div>{date.toLocaleDateString("en-US")}</div>
        </div>
      );
    },
  },
  {
    field: "createdBy",
    headerName: "User",
    width: 100,
    sortable: true,
    valueFormatter: (params) => params.value.replaceAll("@ennabl.com", ""),
  },
  {
    field: "isCorrect",
    headerName: "Good",
    width: 60,
    sortable: true,
    valueFormatter: (params) => (params.value === true ? "Yes" : "No"),
  },
  { field: "prompt", headerName: "Prompt", flex: 0.4 },
  {
    field: "sql",
    headerName: "SQL",
    flex: 0.6,
    renderCell: (params) => <SqlCodeCell row={params.row} />,
  },
];

interface Feedback {
  id: string;
  prompt: string;
  sql: string;
  createdAt: number;
  createdBy: string;
  isCorrect: boolean;
}

export const FeedbackListPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [feedbackList, setFeedbackList] = useState<Array<Feedback>>([]);
  const [visibleFeedbackList, setVisibleFeedbackList] = useState<
    Array<Feedback>
  >([]);
  const [filter, setFilter] = useState<string>("");
  const [showOnlyWrong, setShowOnlyWrong] = useState<boolean>(false);

  const aiService = useService();

  useEffect(() => {
    const filterByCorrectness = showOnlyWrong
      ? feedbackList.filter((f) => !f.isCorrect)
      : feedbackList;
    if (!filter) {
      setVisibleFeedbackList(filterByCorrectness);
    } else {
      setVisibleFeedbackList(
        filterByCorrectness.filter((s) => s.prompt.includes(filter))
      );
    }
  }, [filter, feedbackList, showOnlyWrong]);

  useEffect(() => {
    aiService.listFeedbacks().then((result) => {
      const feedbacks = (result.data as any[]).map((f) => {
        return {
          id: f.id,
          prompt: f.prompt,
          sql: f.sql,
          createdAt: f.created_at,
          createdBy: f.created_by,
          isCorrect: f.is_correct,
        };
      });
      setFeedbackList(feedbacks);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <PageLayout>
        <PageLoader />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div>
        <TextField
          id="outlined-basic"
          label="Search..."
          variant="filled"
          fullWidth={true}
          onChange={(e) => setFilter(e.target.value)}
        />

        <FormControlLabel
          control={
            <Checkbox onChange={(e) => setShowOnlyWrong(e.target.checked)} />
          }
          label="Show only negative?"
        />

        <DataGrid
          getRowHeight={() => "auto"}
          columns={columns}
          rows={visibleFeedbackList}
          rowSelection={false}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 5, page: 0 },
            },
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          sx={{ fontSize: 14, marginTop: 2 }}
        />
      </div>
    </PageLayout>
  );
};
