import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import "prismjs/components/prism-sql";
import "prism-themes/themes/prism-hopscotch.css";
import { useService } from "../services/ai-labeling.service";
import { PageLoader } from "src/components/page-loader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const MIN_PROMPT_LEN = 3;

export const NaicsPage: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");

  const aiService = useService();

  const doSubmit = () => {
    setIsLoading(true);
    aiService.lookupNaics(name, state).then((r) => {
      setResult(r.data);
      setIsLoading(false);
    });
  };

  return (
    <PageLayout>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <h3>Name</h3>
          </Grid>
          <Grid item xs={11}>
            <TextField
              id="outlined-basic"
              variant="filled"
              fullWidth={true}
              onChange={(e) => setName(e.target.value)}
              InputProps={{ style: { fontSize: 14 } }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && name.length >= MIN_PROMPT_LEN) {
                  e.preventDefault();
                  doSubmit();
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <h3>State</h3>
          </Grid>
          <Grid item xs={11}>
            <TextField
              id="outlined-basic"
              label="Optional"
              variant="filled"
              fullWidth={true}
              onChange={(e) => setState(e.target.value)}
              InputProps={{ style: { fontSize: 14 } }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && name.length >= MIN_PROMPT_LEN) {
                  e.preventDefault();
                  doSubmit();
                }
              }}
            />
          </Grid>
        </Grid>

        {isLoading ? (
          <PageLoader />
        ) : (
          <div>
            <Button
              variant="contained"
              color="success"
              size="large"
              onClick={doSubmit}
              style={{ marginTop: 20 }}
              disabled={name.length < MIN_PROMPT_LEN}
            >
              Lookup Naics
            </Button>

            {result != "" ? <h3>Result: {result}</h3> : null}
          </div>
        )}
      </div>
    </PageLayout>
  );
};
