import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { Route, Routes, Navigate } from "react-router-dom";
import { CallbackPage } from "./pages/callback-page";
import { PromptPage } from "./pages/prompt-page";
import { NotFoundPage } from "./pages/not-found-page";
import { FineTunePage } from "./pages/ft-page";
import { SampleListPage } from "./pages/list-page";
import { RunPage } from "./pages/run-page";
import { FeedbackListPage } from "./pages/feedback-page";
import { AccessDeniedPage } from "./pages/access-denied-page";
import { NaicsPage } from "./pages/naics-page";
import { SearchPage } from "./pages/search-page";

export const App: React.FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/prompt" />} />
      <Route
        path="/prompt"
        element={<AuthenticationGuard component={PromptPage} />}
      />
      <Route
        path="/ft"
        element={<AuthenticationGuard component={FineTunePage} />}
      />
      <Route
        path="/ls"
        element={<AuthenticationGuard component={SampleListPage} />}
      />
      <Route
        path="/feedback"
        element={<AuthenticationGuard component={FeedbackListPage} />}
      />
      <Route
        path="/run"
        element={<AuthenticationGuard component={RunPage} />}
      />
      <Route
        path="/naics"
        element={<AuthenticationGuard component={NaicsPage} />}
      />
      <Route
        path="/decisionsSearch"
        element={<AuthenticationGuard component={SearchPage} />}
      />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="/403" element={<AccessDeniedPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
