import React from "react";
import { PageLayout } from "../components/page-layout";

export const AccessDeniedPage: React.FC = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Please make sure you're connected to Twingate!
          <br />
          If Twingate is running - try to restart it.
        </h1>
      </div>
    </PageLayout>
  );
};
