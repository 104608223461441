import React, { useEffect, useState } from "react";
import { PageLayout } from "../components/page-layout";
import "prismjs/components/prism-sql";
import "prism-themes/themes/prism-hopscotch.css";
import { useService } from "../services/ai-labeling.service";
import { PageLoader } from "src/components/page-loader";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { SqlCodeEditor } from "src/components/sql-code-editor";
import { sqlFormatter } from "src/util/sql-formatter";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";

const SqlCodeCell = (props: { row: any }) => {
  const { row } = props;
  const navigate = useNavigate();

  const doubleClick = () => {
    navigate(`/prompt?query=${encodeURIComponent(row.prompt)}`);
  };
  return (
    <div
      style={{ width: "100%" }}
      onClick={(e) => {
        if (e.detail === 2) doubleClick();
      }}
    >
      <SqlCodeEditor
        readOnly={true}
        code={sqlFormatter(row.sql)}
        height="auto"
        widht="100%"
      />
    </div>
  );
};

const columns: GridColDef[] = [
  {
    field: "updatedAt",
    headerName: "Updated At",
    width: 100,
    sortable: true,
    renderCell: (params) => {
      if (!params.value) {
        return <div></div>;
      }
      const timestamp = parseInt(params.value);
      const date = new Date(timestamp);
      return (
        <div>
          <div>{date.toLocaleTimeString("en-US")}</div>
          <div>{date.toLocaleDateString("en-US")}</div>
        </div>
      );
    },
  },
  {
    field: "source",
    headerName: "Source",
    width: 60,
  },
  { field: "prompt", headerName: "Prompt", flex: 0.5 },
  {
    field: "sql",
    headerName: "SQL",
    flex: 0.5,
    renderCell: (params) => <SqlCodeCell row={params.row} />,
  },
];

interface Sample {
  id: string;
  prompt: string;
  sql: string;
  source: string;
  updatedAt?: string;
}

export const SampleListPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sampleList, setSampleList] = useState<Array<Sample>>([]);
  const [visibleSampleList, setVisibleSampleList] = useState<Array<Sample>>([]);
  const [filter, setFilter] = useState<string>("");

  const aiService = useService();

  useEffect(() => {
    if (!filter) {
      setVisibleSampleList(sampleList);
    } else {
      setVisibleSampleList(
        sampleList.filter(
          (s) => s.prompt.includes(filter) || s.sql.includes(filter)
        )
      );
    }
  }, [filter, sampleList]);

  useEffect(() => {
    aiService.listSamples().then((result) => {
      const samples = (result.data as any[]).map((s) => {
        return {
          id: s.key,
          prompt: s.prompt,
          sql: s.sql,
          source: s.source,
          updatedAt: s.updated_at,
        };
      });
      setSampleList(samples);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <PageLayout>
        <PageLoader />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div>
        <TextField
          id="outlined-basic"
          label="Search..."
          variant="filled"
          fullWidth={true}
          onChange={(e) => setFilter(e.target.value)}
        />

        <DataGrid
          getRowHeight={() => "auto"}
          columns={columns}
          rows={visibleSampleList}
          rowSelection={false}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 5, page: 0 },
            },
            sorting: {
              sortModel: [{ field: "updatedAt", sort: "desc" }],
            },
          }}
          sx={{ fontSize: 14, marginTop: 2 }}
        />
      </div>
    </PageLayout>
  );
};
