import { callExternalApi } from "./external-api.service";
import { useAuth0 } from "@auth0/auth0-react";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
const apiSearchServerUrl = process.env.REACT_APP_SEARCH_API_SERVER_URL;

export const sendPrompt = (accessToken: string, prompt: string) =>
  callExternalApi(accessToken, {
    url: `${apiServerUrl}/prompt-v2`,
    method: "POST",
    data: { prompt },
  });

export const sendSample = (
  accessToken: string,
  data: { prompt: string; sql: string; source: string }
) =>
  callExternalApi(accessToken, {
    url: `${apiServerUrl}/sample`,
    method: "POST",
    data,
  });

export const listPendingSamples = (accessToken: string) =>
  callExternalApi(accessToken, {
    url: `${apiServerUrl}/lst-pending`,
    method: "GET",
  });

export const listSamples = (accessToken: string) =>
  callExternalApi(accessToken, {
    url: `${apiServerUrl}/lst`,
    method: "GET",
  });

export const listFeedbacks = (accessToken: string) =>
  callExternalApi(accessToken, {
    url: `${apiServerUrl}/lst-feedback`,
    method: "GET",
  });

export const executePrompt = (accessToken: string, prompt: string) =>
  callExternalApi(accessToken, {
    url: `${apiServerUrl}/run`,
    method: "POST",
    data: { prompt },
  });

export const lookupNaics = (
  accessToken: string,
  name: string,
  state: string | null
) =>
  callExternalApi(accessToken, {
    url: `${apiServerUrl}/naics`,
    method: "POST",
    data: { name, state },
  });

export const decisionsSearch = (accessToken: string, query: string) =>
  callExternalApi(accessToken, {
    url: `${apiSearchServerUrl}/search?q=${encodeURIComponent(query)}`,
    method: "GET",
  });

export const sendFeedback = (
  accessToken: string,
  prompt: string,
  sql: string,
  isCorrect: boolean
) =>
  callExternalApi(accessToken, {
    url: `${apiServerUrl}/feedback`,
    method: "POST",
    data: { prompt, sql, is_correct: isCorrect },
  });

export const useService = () => {
  const { getAccessTokenSilently } = useAuth0();
  return {
    sendPrompt: async (prompt: string) =>
      sendPrompt(await getAccessTokenSilently(), prompt),
    executePrompt: async (prompt: string) =>
      executePrompt(await getAccessTokenSilently(), prompt),
    lookupNaics: async (name: string, state: string) =>
      lookupNaics(await getAccessTokenSilently(), name, state),
    sendSample: async (data: { prompt: string; sql: string; source: string }) =>
      sendSample(await getAccessTokenSilently(), data),
    listPendingSamples: async () =>
      listPendingSamples(await getAccessTokenSilently()),
    listSamples: async () => listSamples(await getAccessTokenSilently()),
    listFeedbacks: async () => listFeedbacks(await getAccessTokenSilently()),
    sendFeedback: async (prompt: string, sql: string, isCorrect: boolean) =>
      sendFeedback(await getAccessTokenSilently(), prompt, sql, isCorrect),
    decisionsSearch: async (query: string) =>
      decisionsSearch(await getAccessTokenSilently(), query),
  };
};
